/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createColumnHelper } from "@tanstack/react-table";
import { Patient, Referral, ReferralOrigins } from "../../../services/MediaPatient";
import { ReferralStatus, useGetReferralStatusesQuery } from "../../../services/ReferralStatusService";
import React from "react";
import moment from "moment";
import DeleteWithConfirmation from "../../shared/buttons/DeleteWithConfirmation";
import pause from "../../../assets/icons/pause.svg";
import edit from "../../../assets/icons/edit.svg";
import play from "../../../assets/icons/play.svg";
import checkCircle from "../../../assets/icons/checkCircle.svg";
import noSymbol from "../../../assets/icons/noSymbol.svg";
import { User } from "../../../services/UserService";
import store from "../../../redux/store";
import { parsePhoneNumber } from "awesome-phonenumber";
import copyIcon from "../../../assets/icons/copy.svg";
import ImageDisplay from "../../ImageDisplay";
import { CurrentUser } from "../../../services/User";

export default class ReferralsTable {
  static columns(
    destroyReferral: (referral: Referral) => void,
    accessToken: string
  ) {
    const currentUser = store.getState().currentUser as CurrentUser;
    const { data: referralStatusData } =
    useGetReferralStatusesQuery({
      tenantSlug: currentUser.tenantSlug,
      accessToken: accessToken,
    },   {
      skip: !currentUser?.tenantSlug || !accessToken,
    });
    const referralStatuses: ReferralStatus[] = referralStatusData?.data || [];

    const columnHelper = createColumnHelper<Referral>();
    const [isCoppied, setIsCoppied] = React.useState<number | null>(null);

    const displayName = (patient: Patient) => {
      if (hasFirstAndLastName(patient)) {
        return `${patient.firstName} ${patient.lastName}`;
      }
      else {
        return "N/A";
      }
    };
    
    const handleCopy = (text: string | undefined, index: number) => {
      if (!text) return;
      navigator.clipboard.writeText(text.replace(/\s+/g, ""));
    
      setIsCoppied(index);
      setTimeout(() => {
        setIsCoppied(null);
      }, 1500);
    };

    const isHL7File = (url: string) => {
      return /\.(hl7)$/i.test(url) || /\.(hl7.txt)$/i.test(url);
    };

    const defaultColumns = [
      columnHelper.display({
        header: "Name",
        cell: props => <div className="cursor-pointer flex items-center py-0.5">
          <div className="cursor-pointer py-1">
            {
              !isHL7File(props.row.original.url) && (
                <ImageDisplay 
                  imageKey={props.row.original.url}
                  className="object-fill h-12 w-12 rounded"
                />
              )
            }
  
            {
              isHL7File(props.row.original.url) && (
                <div className="h-12 w-12 flex flex-col items-center justify-center rounded" style={{backgroundColor: "#ebebeb"}}>
                  <p className="font-medium">HL7</p>
                </div>
              )
            }
          </div>

          {
            <div className="pl-2 font-medium">{displayName(props.row.original.patient)}</div>
          }
        </div>
      }),
      columnHelper.accessor("status", {
        enableColumnFilter: true,
        header: () => <div className="text-left flex">
          Status
        </div>,
        cell: props => <div 
          className="inline-block rounded-full cursor-pointer" 
          style={{ backgroundColor: referralStatuses?.find(rs => rs.name === props.row.original.status)?.metadata?.color || "gray"}}
        >
          <div className="flex items-center py-1 px-2 cursor-pointer">
            <img 
              className="object-fill h-5 w-5 pr-1" 
              src={iconForStatus(referralStatuses?.find(rs => rs.name === props.row.original.status)?.metadata?.iconName)}
              alt="Referral" 
              style={{ fill: "white" }}
            />
            <div className="font-medium text-white text-xs">{props.row.original.status}</div>
          </div>
        </div>
      }),
      columnHelper.display({
        header: "Mobile Phone",
        cell: props => <div className="cursor-pointer">
          <div className="flex items-center font-medium group">
            {
              parsePhoneNumber(props.row.original.patient.mobilePhoneNumber).number?.national
            }

            <button 
              onClick={(e) => {
                e.stopPropagation();
                handleCopy(
                  parsePhoneNumber(props.row.original.patient.mobilePhoneNumber).number?.national,
                  props.row.index
                );
              }}
              className="ml-2 "
            >
              {
                isCoppied !== props.row.index && <img 
                  alt="Copy Icon" 
                  className="w-4 h-4 text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity" 
                  src={copyIcon} 
                />
              }

              {
                isCoppied === props.row.index && (
                  <div className="flex items-center w-4">
                    <div className=" =h-4 text-green-600 group-hover:opacity-100 transition-opacity">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.8" stroke="currentColor" className="w-4 h-4 group-hover:opacity-100 transition-opacity">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15L15 9.75M21 12a9 9 0 1 1-18 0a9 9 0 0 1 18 0Z" />
                      </svg>
                    </div>
                    <div className="text-green-600 group-hover:opacity-100 transition-opacity">
                      Copied
                    </div>
                  </div>

                )
              }
            </button>
          </div>
        </div>
      }),
      columnHelper.accessor("user", {
        enableColumnFilter: true,
        header: () => <div className="text-left flex">
          Assigned To
        </div>,
        cell: props => <div className="cursor-pointer">
          {
            props.row.original.user && <div className="font-medium">{fullName(props.row.original.user)}</div>
          }

          {
            !props.row.original.user && <div className="font-medium">N/A</div>
          }
        </div>
      }),
      columnHelper.accessor("origin", {
        enableColumnFilter: true,
        header: () => <div className="text-left flex">
          Origin
        </div>,
        cell: props => <div className="cursor-pointer">
          <div className="inline-block">
            <div className={`font-medium ${originColor(props.row.original)} rounded-full py-1 px-2 text-white text-xs`}>
              { originName(props.row.original) }
            </div>
          </div>
        </div>
      }),
      columnHelper.accessor("createdAt", {
        id: "createdAt",
        header: () => <div className="text-left">Created At</div>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        cell: info => <div>
          <div className="font-medium">{moment.utc(info.getValue()).local().format("DD/MM/YY")}</div>
          <div className="text-xs text-gray-500">{moment.utc(info.getValue()).local().format("h:mm:ss a") }</div>
        </div>,
      }),
      columnHelper.display({
        header: "Actions",
        cell: props => <div className="flex text-left z-10 cursor-pointer relative">
          <div 
            className="inline-block mr-2" 
          >
            <img src={edit} />
          </div>
          <div 
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            { <DeleteWithConfirmation destroy={()=> destroyReferral(props.row.original)}/> }
          </div>
        </div>
      }),
    ];
    
    return defaultColumns;
  }
}

const hasFirstAndLastName = (patient: Patient) => {
  return patient.firstName && patient.lastName;
};

export const fullName = (user: User) => {
  if (user.firstName || user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  } else {
    return "N/A";
  }
};

export function iconForStatus(status: string | undefined) {
  let iconPath;
  switch (status) {
    case "play":
      iconPath = play;
      break;
    case "pause":
      iconPath = pause;
      break;
    case "checkCircle":
      iconPath = checkCircle;
      break;
    default:
      iconPath = noSymbol;
      break;
  }

  return iconPath;
}

export function originName(referral: Referral) {
  if (referral.origin === ReferralOrigins.apollo_client) {
    return "Apollo Client";
  } else if (referral.origin === ReferralOrigins.company_website) {
    return "Company Website";
  } else if (referral.origin === ReferralOrigins.patient_upload_image_client) {
    return "Text Message";
  }
}

// export function originName(referral: ReferralOrigins) {
//   if (referral.origin === ReferralOrigins.apollo_client) {
//     return "Apollo Client";
//   } else if (referral.origin === ReferralOrigins.company_website) {
//     return "Company Website";
//   } else if (referral.origin === ReferralOrigins.patient_upload_image_client) {
//     return "Text Message";
//   }
// }

export function originColor(referral: Referral) {
  if (referral.origin === ReferralOrigins.apollo_client) {
    return "bg-green-500";
  } else if (referral.origin === ReferralOrigins.company_website) {
    return "bg-pink-500";
  } else if (referral.origin === ReferralOrigins.patient_upload_image_client) {
    return "bg-blue-500";
  }
}