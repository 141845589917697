import React, { ReactNode, useEffect } from "react";
import SideNavBar from "./SideNavBar";
import TopNavBar from "./TopNavBar";
import Hotjar from "@hotjar/browser";

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  useEffect(() => {
    // Hotjar tracking
    const siteId = 4988460;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  return (
    <body className="antialiased bg-body text-body font-body">
      <div>
        <SideNavBar />
        <div className="mx-auto">
          <section className="body-section min-h-screen bg-gray-100" style={{marginLeft: "90px"}}>
            <TopNavBar />
            { children }
          </section>
        </div>
      </div>
    </body>
  );
};

export default AppLayout;
