import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import store from "../../redux/store";
import { CurrentUser } from "../User";
import { Patient } from "../MediaPatient";

interface Location {
  id: string
  name: string
}

interface Form {
  id: string,
  formType: FormType,
}

interface FormType {
  id: string
  name: string
}

export interface FormAnswer {
  id: string
  tenantId: string
  location: Location
  form: Form
  patient: Patient
  pdfUrl: string
  answersData: Record<string, unknown>
  createdAt: string
  updatedAt: string
}

interface FormAnswersResponseApiResponse {
  data: FormAnswer[],
  metadata: {
    totalCount: number
  }
}

interface FormAnswersRequestParams {
  itemsPerPage?: number
  itemOffset?: number
  query: string | null
  locationIds: string[] | null
}

export const formAnswers = createApi({
  reducerPath: "surveyResponsesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST_URL,
    prepareHeaders: (headers) => {
      const accessToken = store.getState().accessToken;
      const currentUser = store.getState().currentUser as CurrentUser;

      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      headers.set("Tenant-Slug", currentUser.tenantSlug);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFormAnswers: builder.query<FormAnswersResponseApiResponse, FormAnswersRequestParams>({
      query: ({
        itemsPerPage = 10,
        itemOffset = 0,
        query,
        locationIds
      }) => {
        let apiUrl = `/form_answers?itemsPerPage=${itemsPerPage}&itemOffset=${itemOffset}`;

        if (query) {
          apiUrl += `&query=${query}`;
        }

        if (locationIds) {
          apiUrl += `&locationIds=${locationIds.join(",")}`;
        }

        return {
          url: apiUrl,
          method: "GET",
        };
      },
      keepUnusedDataFor: 3,
    }),
  }),
});

export const { useGetFormAnswersQuery } = formAnswers;
