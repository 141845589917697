/* eslint-disable @typescript-eslint/no-explicit-any */
import { withAuthenticationRequired, WithAuthenticationRequiredOptions } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import AppLayout from "../layout/AppLayout";

interface GuardProps {
  component: ComponentType<any>;
}

const PrivateRoute: React.FC<GuardProps> = ({ component: Component }) => {
  const AuthenticatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => (
      <div className="flex flex-col justify-center items-center min-h-screen page-layout">
        <h2 className="text-2xl font-bold pb-2">Elvati</h2>
        <p className="pb-2">Loading</p>
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    ),
  } as WithAuthenticationRequiredOptions);

  return (
    <AppLayout>
      <AuthenticatedComponent />
    </AppLayout>
  );
};

export default PrivateRoute;
