import { useMemo, useState } from "react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import React from "react";
import { FormAnswer, useGetFormAnswersQuery } from "../../services/forms/FormAnswersService";
import S3Service from "../../services/aws/S3Service";
import moment from "moment";
import SearchForm from "../layout/SearchForm";
import store from "../../redux/store";
import { Location } from "../../models/Location";

const FormAnswers: React.FC = ()  => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);

  store.subscribe(() => {
    const state = store.getState();
    setCurrentLocation(state.currentLocation);
  });

  const { data, isLoading, isError } = useGetFormAnswersQuery({
    itemsPerPage: pageSize,
    itemOffset: pageIndex * pageSize,
    query: searchQuery,
    locationIds: currentLocation?.id ? [currentLocation.id] : null,
  });

  const viewPdf = async (imageKey: string) => {
    const signedUrl = await S3Service.getPresignedUrl(imageKey, 1440);

    if (!signedUrl) {
      console.error("Failed to get signed URL");
      return;
    }
    window.open(signedUrl, "_blank"); 
  };

  const columns = useMemo<ColumnDef<FormAnswer>[]>(
    () => [
      {
        header: "Patient",
        accessorFn: row => `${row.patient.firstName} ${row.patient.lastName}`,
      },
      {
        header: "Mobile phone",
        accessorFn: row => row.patient.mobilePhoneNumber,
      },
      {
        header: "DOB",
        accessorFn: row => moment(row.patient.dateOfBirth).format("DD-MM-YYYY").toString(),
      },
      {
        header: "Form Type",
        accessorFn: row => row.form.formType.name,
      },
      {
        header: "Completed at",
        cell: ({ row }) => {
          const formattedDate = moment.utc(row.original.updatedAt).local().format("DD/MM/YYYY");
          const formattedTime = moment.utc(row.original.updatedAt).local().format("h:mm:ss A");
      
          return (
            <div className="flex flex-col">
              <p>{formattedDate}</p>
              <p className="text-xs text-gray-600">{formattedTime}</p>
            </div>
          );
        },
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              viewPdf(row.original.pdfUrl);
            }}
            className="text-blue-500 hover:text-blue-700 cursor-pointer"
          >
            View Answers
          </a>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: data?.data || [],
    columns,
    pageCount: Math.ceil((data?.metadata.totalCount || 0) / pageSize), 
    state: {
      pagination: { pageIndex, pageSize },
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  if (isLoading) {
    return (
      <div className="flex justify-center pt-8">
        <span className="inline-block h-8 w-8 animate-spin text-gray-900 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg" role="alert">
        Error loading form answers page
      </div>
    );
  }

  return (
    <section className="px-12">
      <section className="w-full mx-auto pt-8 pb-4">
        <div className="w-full lg:w-auto flex items-center mb-4 lg:mb-0">
          <h2 className="text-4xl font-bold">Form Answers</h2>
          <span className="inline-block py-1 px-4 ml-2 rounded-full text-white bg-indigo-500">
            {data?.metadata?.totalCount || 0} Total
          </span>
        </div>

        <div className="pt-4">
          <div className="flex justify-end items-center pb-4">
            <SearchForm 
              requestSearch={(query) => {
                setSearchQuery(query);
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto rounded-lg border">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="hover:bg-gray-50">
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="flex space-x-2">
            <button
              className="px-4 py-2 border rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </button>
            <button
              className="px-4 py-2 border rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </button>
          </div>
          
          <div className="text-sm text-gray-700">
            Page {pageIndex + 1} of {table.getPageCount()}
          </div>

          <select
            className="border rounded-md p-2"
            value={pageSize}
            onChange={e => {
              setPagination(p => ({ ...p, pageSize: Number(e.target.value) }));
            }}
          >
            {[10, 20].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </section>
    </section>
  );
};

export default FormAnswers;
